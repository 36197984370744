import { useState, ChangeEventHandler, KeyboardEventHandler } from "react";
import { animated, useSpring } from "@react-spring/web";
import I18n from "i18n-js";
import produce from "immer";
import AppsOutageOutlinedIcon from "@mui/icons-material/AppsOutageOutlined";
import AppSettingsAltOutlinedIcon from "@mui/icons-material/AppSettingsAltOutlined";
import { RouteProps } from "./type";
import { GoBackBtn } from "./GoBackBtn";

export const EnableLab = (props: RouteProps) => {
  const PASSWORD_LENGTH = 6;
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [animationStyles, animationApi] = useSpring(() => ({
    from: { x: 0 },
  }));
  const [password, setPassword] = useState(["", "", "", "", "", ""]);

  const handleGoBack = () => props.navigate("/");

  const handlePasswordChange = (
    index: number
  ): ChangeEventHandler<HTMLInputElement> => (e) => {
    setError(false);
    const next = e.target.nextElementSibling as HTMLInputElement;
    if (e.target.value || e.target.value === "") {
      setPassword(
        produce(password, (draft) => {
          draft[index] = e.target.value;
        })
      );
      if (next && e.target.value !== "") {
        next.focus();
      }
    }
  };

  const handleClick = () => {
    if (!disabled && !error) {
      setDisabled(true);
      setError(true);
      setDisabled(false);
      animationApi.start({
        to: async (next, cancel) => {
          await next({ x: 10 });
          await next({ x: -10 });
          await next({ x: 10 });
          await next({ x: -10 });
        },
        config: { duration: 100 },
      });
    }
  };

  const handleEnterKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.keyCode === 13 && !(password.length === 0 || error)) {
      handleClick();
    }
  };

  return (
    <div className="flex-col-center !justify-start !flex-1 w-full h-full">
      <div className="flex-col-center w-full px-6 gap-8 mt-[32px] mb-[30px]">
        <div className="flex-row-el flex-center ">
          <p className="text-body text-[20px] text-brown/100">
            『まなんでパズル』認証コード（企業提携コード）をお持ちの方は、下記に指定されたコードを入力してください。
          </p>
        </div>

        <div className="relative flex-row-el gap-4 flex-center w-full py-6 bg-white/40 rounded-[8px]">
          <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
            <AppSettingsAltOutlinedIcon />
            <p className="text text-textcolor/black !text-[16px] !px-[8px]">
              認証コード入力
            </p>
          </div>

          <div className="flex-col-center">
            <p className="text text-textcolor/black !leading-[30px]">
              {I18n.t("MSG_SETTING_PARENTS_LINK_ADD_USER_AUTH_CODE_TITLE")}
            </p>
            <animated.div
              className="flex-row-center"
              style={animationStyles}
              onKeyDown={handleEnterKeyDown}
            >
              {Array(PASSWORD_LENGTH)
                .fill(0)
                .map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={password[index]}
                    onChange={handlePasswordChange(index)}
                    className={`relative flex-col-el flex-center w-14 outline-none appearance-none overflow-hidden border-solid border-[2px] border-l-0 border-gray/40 rounded-none first:border-l-[2px] first:rounded-l-[8px] last:rounded-r-[8px] text-body text-textcolor/black text-center text-[32px] ${
                      error && "!border-danger"
                    }`}
                  />
                ))}
            </animated.div>
          </div>

          <div className="flex-row-el flex-center mt-8 alpha">
            <button
              onClick={handleClick}
              className="btn btn-primary"
              disabled={password.length === 0 || error}
            >
              <p className="text-body text-white text-[24px] !mb-[3px]">決定</p>
            </button>
          </div>
        </div>

        <div className="relative flex-row-el gap-4 items-center justify-start w-full bg-white/40 p-8 rounded-[8px]">
          <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
            <AppsOutageOutlinedIcon />
            <p className="text text-textcolor/black !text-[16px] !px-[8px]">
              許可されたコンテンツ（カセット）
            </p>
          </div>

          <div className="flex-row-center gap-2 h-[168px]"></div>

          <div className="flex-row-el flex-center absolute -z-1 w-full">
            <p className="text-body text-[24px] text-textcolor/black">
              許可された追加コンテンツ（カセット）はありません。
            </p>
          </div>
        </div>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
